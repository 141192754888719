import api from './index'
import { axios } from '@/utils/request'

// 获取课程分类
export function courseCategory() {
    return axios({
        url: api.CourseCategory,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取单个课程详情
export function courseCategoryInfo(parameter) {
    return axios({
        url: api.CourseCategoryInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 新增课程分类
export function courseCategoryCreate(parameter) {
    return axios({
        url: api.CourseCategoryCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改课程分类
export function courseCategorySave(parameter) {
    return axios({
        url: api.CourseCategorySave,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除课程分类课程分类
export function courseCategoryDelete(parameter) {
    return axios({
        url: api.CourseCategoryDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 课程列表
export function courseList(parameter) {
    return axios({
        url: api.CourseList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 课程详情
export function courseInfo(parameter) {
    return axios({
        url: api.CourseInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 新增课程
export function courseCreate(parameter) {
    return axios({
        url: api.CourseCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 编辑课程
export function courseSave(parameter) {
    return axios({
        url: api.CourseSave,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除课程
export function courseDelete(parameter) {
    return axios({
        url: api.CourseDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
